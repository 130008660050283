
.item-info .z-center{
    line-height: 18px;
}
.item-info h3{
    font-family: Impact;
    font-weight: 400;
    display:block !important;
    color: white;
}
.item-info .z-center .z-smallstats {
    white-space:nowrap !important;
}


.item-info .myPurple{
    color: purple;
    display:block;
}

.item-info .myYellow{
    color: yellow;
    display:block;
}

.item-info .z-smallstats .z-white{
    color: #fcb100 !important;
}
.item-info .z-center a{
    color: rgb(113,227,71) !important;
}
.item-info .z-center .zso_defense{
    color: rgb(221,228,202);
}

.item-info {
    text-align: center;
    color: white !important;
    text-transform: none !important;
}
.item-info .z-center .rl-title-uniques-items{
    color: #fcb100!important;
}

.item-info .z-center .zso_defense{
    color: #dedac8;
}
.item-info .z-center .zso_durability{
    color: #dedac8;
}
.item-info .z-center .zso_rqstr{
    color: #dedac8;
}
.item-info .z-center .zso_rqlevel{
    color: #dedac8;
}
.item-info .z-center .zso_qualitylvl{
    color: #dedac8;
}

.item-info .z-center .zso_trclass{
    color: #dedac8;
}
.item-info .z-center .zso_maxsock{
    color: #dedac8;
}

.item-card-desc {
    background: #000000;
    font-size: 16px;
    line-height: 32px;
    padding: 16px;
}