 html,body{
 }

.layout-main {
    background-color: #f1f2f3;
    width: 100%;
    //height: 100vh;
    min-width: 1400px;
    //min-height: 698px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}
.layout-left {
    background: url("images/login-2.png") no-repeat right;
    background-size: 66%;
    height: 520px;
}

.layout-right {
    //width: 700px;
    //float: right;
    height: calc(100vh - 188px);
}

.layout-bottom{
    //position: absolute;
    //bottom: 0px;
    width: 100%;
}

.login-div {
    position: relative;
    clear: both;
    width: 500px;
    margin-top: 80px; //calc((100% - 550px) / 2);
    margin-left: 20px;
    background-color: rgba(255,255,255,0.80);
    border-radius: 8px;
    padding: 60px 25px;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
    box-sizing: border-box;
    .login-form {
        margin: auto;
        max-width: 300px;
        input {
            margin: auto;
            max-width: 300px;
            height: 24px;
        }
    }
    .login-form-forgot {
        float: right;
    }
    .ant-col-rtl .login-form-forgot {
        float: left;
    }
    .login-form-button {
        width: 100%;
        height: 36px;
        border: none;
    }
}
.ant-form-itemw {
    padding-bottom: 8px;
}
.login-logo {
    display: none;
    width: 200px;
    height: 60px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #e24e50ee;
    background-image: url(./images/logo-1-w.png);
    background-repeat: no-repeat;
    background-size: 128px 35px;
    background-position: center center;
}


@primary-color: #46a9ff;@link-color: #46a9ff;@font-size-base: 12px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.85);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);