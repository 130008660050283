
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
    border-bottom: 0;
}

a.ant-typography {
    color: rgba(0, 0, 0, 0.85);
}
.ant-tooltip-inner a.ant-typography {
    color: rgba(255,255, 255, 1);
}

.ant-pro-layout .ant-pro-sider .ant-layout-sider-children {
    padding-inline: 1px !important;
    padding-top: 44px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    margin-inline: 1px;
}

.ant-pro-table-alert {
    margin-top: -60px;
    width: 50%;
}