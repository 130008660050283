body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fcfdfe;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.ant-btn:not(.ant-btn-link) {
  //padding-left: 25px;
  //padding-right: 25px;
}

.ant-btn-icon-only {
  padding: 0px !important;
}

.ant-drawer-body {
  background-color: #f0f2f4;
}

@red: #e54546;
@blue: #46a9ff;
@green: #77d472;
@grey: #c3c3c3;
@normal: #333333;

.queryForm {
  .queryItem{
    width: 120px;
  }
}
@primary-color: #46a9ff;@link-color: #46a9ff;@font-size-base: 12px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.85);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);