//  TO DO 修改logo及颜色

// Plus
//@main-head-background: #1D2088;
//@main-head-logo: url("images/logo-ksf-w.png") no-repeat center;

// Sam
//@main-head-background: #333333;
//@main-head-logo: url("images/logo-sam1.png") no-repeat center;


//background: rgba(60,141,182,0.8); //天青
//background-image: linear-gradient(to left, #bac8e0 0%, #6a85b6 100%);
//background-image: linear-gradient(-225deg, #7085B6 0%, #87A7D9 50%, #DEF3F8 100%);


.center {
    text-align: center
}

.right {
    float: right;
    text-align: right;
}

.red {
    color: #e54546
}

.blue {
    color: #46a9ff
}

.green {
    color: #77d472
}

.grey {
    color: #c3c3c3
}

.normal {
    color: #333333
}

.high-light {
    color: #e54546;
    font-size: 13px;
    font-weight: 600;
}

.row1 {
    background-color: #ffffff;
}
.row2 {
    background-color: #fafafa;
}
.ant-table-thead > tr >th{
    // 表头
    //background: #eaf6fd !important;
    background: #f6f8fa !important;
}

.ant-breadcrumb {
    margin-left: 2em !important;
}

.cell-unbalance { background-color: #ffffb8 !important;}

.strong {
    font-size: 1.2em;
    font-weight: 600;
}

.flex-blank {
    flex: 1;
}

.right-split {
    border-right: 1px solid #c3c3c3;
}

.line-blank {
    clear: both;
    height: 24px;
    width: 24px;
}

.ant-layout-sider, .ant-layout, .ant-drawer-body {
    background: #ffffff !important;
}
#invoiceForm  {
    .ant-upload-list-picture-card-container {
        max-width: 164px;
        height: 94px;
    }
    .ant-upload-select-picture-card{
        width: 94px;
        height: 94px;
    }
    .ant-upload-list-item{
        padding: 0;
    }
}

.fullModal {
    width: 95% !important;
    height: 95% !important;
}

.ant-message-notice-content {
    border: 1px solid #98d1fb;
    background-color: #e7f6fe;
    margin-top: 64px;
    padding: 12px;
}

.message_error .ant-message-notice-content {
    border: 1px solid #fecec8;
    background-color: #fff3f0;
}

.message_success .ant-message-notice-content {
    border: 1px solid #b1ec99;
    background-color: #f5ffef;
}

.message_info .ant-message-notice-content {
    border: 1px solid #98d1fb;
    background-color: #e7f6fe;
}

.message_warning .ant-message-notice-content {
    border: 1px solid #fae89a;
    background-color: #fdfce8;
}

#main-header {
    width: 100vw;
    color: #ffffff;
    //background: @main-head-background;
    background-size: 100% 100%;
    height: 60px;
    padding-left: 0;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    z-index: 99;

    .ant-menu-item {
        color: #ffffffaa;
    }

    .ant-menu-item-active {
        color: #ffffff;
    }

    .logo {
        //background: @main-head-logo;
        background-repeat: no-repeat;
        background-position:  center;
        background-size: contain;
        width: 200px;
        height: 36px;
        margin: 12px 20px;
        border-right: 1px solid rgba(255, 255, 255, 0.4);
    }
}

#main-header:before {
    content: '';
    display: table;
}


.main-header-menu {
    background: none !important;
    color: #fff !important;
    border-bottom: 0px solid #f0f0f0 !important;
    margin-left: 40px !important;
    min-width: 100px;

    .ant-menu-item a,
    .ant-menu-submenu span {
        color: rgba(255, 255, 255, 0.85) !important;
    }

    .ant-menu-item a:hover,
    .ant-menu-submenu:hover span,
    .ant-menu-item-selected a {
        color: rgba(255, 255, 255, 1) !important;
    }

    .ant-menu-item:hover::after,
    .ant-menu-item-selected::after,
    .ant-menu-submenu:hover::after,
    .ant-menu-submenu-open::after {
        border-bottom: 4px solid #ffffff !important;
    }
}

#main-footer {
    clear: both;
    text-align: center;
    //height: 128px;
    color: @primary-color ;
    line-height: 16px;
    background-color: rgba(0,0,0,0.02);
    //font-weight: 200;
    margin-top: 0;
    padding: 16px 0;

    a {
        color: #6998afcc ;
        //font-weight: 200;
        font-size: 12px;
    }
    a:hover{
        color: @primary-color ;
    }
}

sup {
    font-size: 12px;
    zoom: 0.7;
}

.main-body {
    width: 100vw;
    margin: auto;
    padding: 24px 0px;
    background: #ffffff;
    min-height: calc(100vh - 188px) !important;
}

.main-content {
    padding: 0px 24px;
    min-height: 500px;
    background: #ffffff;
}

.query_form > .ant-row > .ant-col > .ant-form-item {
    margin-bottom: 8px;
}

.dialog-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.dialog-title .right {
    margin-right: 64px;
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
}

.row-dragging td {
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

.draggable-tree {
    margin: 50px 70px;
}

.title1 {
    font-size: 28px;
    font-weight: 500
}

.title2 {
    font-size: 24px;
    font-weight: 400
}

.title3 {
    font-size: 16px;
    font-weight: 200
}

.num0 {
    font-size: 14px;
    margin: 0 8px;
    font-weight: 300
}

.num1 {
    font-size: 32px;
    margin: 0 8px;
    font-weight: 500;
    font-family: Impact !important;
}

.num2 {
    font-size: 24px;
    margin: 0 8px;
    font-weight: 500;
    font-family: Impact !important;
}

.num3 {
    font-size: 24px;
    margin: 0 8px;
    font-weight: 500;
    font-family: Impact !important;
}

.tabs-body {
    //background-color: #ffffff;
}

.ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0px !important;
}

.tabs-content {
    padding: 24px;
    background-color: #ffffff;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
}

.tabs-title1 {
    font-size: 16px;
    font-weight: 500;
    margin: 32px 0 16px 0;
}

#functionTree, #modalUserInfo {
    height: calc(100vh - 380px);
    min-height: 400px;
    overflow: scroll;
}

.copy-value {
    display: inline-block;
    width: 220px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.copy-icon {
    display: inline-block;
}
.ant-drawer-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
}

.applyInfo {
    .empStoreRow{
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid #f2f2f2;
    }
}
.emp-store-list {
    padding-top: 8px;
    padding-bottom: 8px;
    //margin-bottom: 8px;
    border-bottom: 1px solid #f2f2f2;
}
.w-e-bar {
    font-size: 12px !important;
}
.edit-image-show {
    width: 104px;
    height: 104px;
    padding: 10px;
    border: 1px solid #d9d9d9;
    img {
        border: #ffffff;
        object-fit: contain;
        width: 84px;
        height: 84px;
    }
}
@primary-color: #46a9ff;@link-color: #46a9ff;@font-size-base: 12px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.85);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);